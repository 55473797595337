import React from 'react'
import { Container, Ref, Sticky } from 'semantic-ui-react';

import HeaderMenu from 'components/core/AppBar';
import Footer from 'components/core/Footer';

export default function HomeLayout(props) {
	const contextRef = React.createRef();
	return (
		<Ref innerRef={contextRef}>
			<Container fluid>
				<div 
					style={{
						width:'100%', 
						// Bugfix for vertical top scroll: sidebar appearing behind page content
						marginTop: '-1px',
						// set rail height prevents masking of page content
						height: '0px',
						position: (props.authenticated ? 'fixed' : null),
						zIndex: (props.authenticated ? 850 : 0) //if authenticated ensure the header is on top of all home page(s) content
					}}>
					<Sticky context={contextRef}>
						<HeaderMenu {...props} />
					</Sticky>
				</div>
				{props.children}
				<Footer className='footer' />
			</Container>
		</Ref>
	);
};
